// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---custom-pages-article-js": () => import("./../../../customPages/article.js" /* webpackChunkName: "component---custom-pages-article-js" */),
  "component---custom-pages-exercise-js": () => import("./../../../customPages/exercise.js" /* webpackChunkName: "component---custom-pages-exercise-js" */),
  "component---custom-pages-workout-js": () => import("./../../../customPages/workout.js" /* webpackChunkName: "component---custom-pages-workout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-js": () => import("./../../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-exercise-js": () => import("./../../../src/pages/exercise.js" /* webpackChunkName: "component---src-pages-exercise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-workout-js": () => import("./../../../src/pages/workout.js" /* webpackChunkName: "component---src-pages-workout-js" */)
}

